import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import classs from "../utils/classs.js"
import "../index.css"

import logo from "../../assets/images/logo.png"

const interval = (fn, t, i = 0) => fn(0) || setInterval(() => fn(++i), t)

export default styled(({ className }) => {
  const texts = [
    "Centralisation des données des campagnes digitales",
    "Vision plus fine de l’intérêt des audiences ",
    "Aide à l’optimisation des dépenses ",
    "Renouvellement du public",
  ]
  const [curr, setCurr] = useState(0)
  useEffect(() => {
    const id = interval(t => setCurr(t % texts.length), 3000)
    return () => clearInterval(id)
  }, [texts.length])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ariane</title>
        <link rel="canonical" href="https://ariane.app/" />
      </Helmet>
      <div className={className}>
        <nav>
          <div className="login">
            <a href="https://console.ariane.app/">Connexion</a>
          </div>
        </nav>
        <header>
          <div className="logo">
            <img src={logo} alt={"Ariane"} />
          </div>
        </header>
        <div className="mainline">
          Faire découvrir de nouveaux films au public
        </div>
        <div className="headline">
          {texts.map((text, i) => (
            <span key={i} className={classs({ active: curr === i })}>
              {text}
            </span>
          ))}
        </div>
        <a className="button" href="mailto:contact@balthus.com">
          Rejoignez-nous
        </a>
      </div>
    </>
  )
})`
  @keyframes head {
    0% {
      transform: translateY(-20px) rotateX(-90deg);
      opacity: 0;
      filter: blur(0px);
    }
    10% {
      transform: translateY(0px) rotateX(0deg);
      opacity: 1;
      filter: blur(0px);
    }
    90% {
      transform: translateY(0px) rotateX(0deg);
      opacity: 1;
      filter: blur(0px);
    }
    100% {
      transform: translateY(20px) rotateX(90deg) scale(1, 0.2);
      opacity: 0;
      filter: blur(9px);
    }
  }

  & {
    font-family: Arial;
    height: 95vh;
    display: grid;
    grid-gap: calc(var(--gap) * 2);
    grid-template-rows: min-content min-content min-content;
    background: var(--grey1);
    justify-items: center;
    align-content: center;
    padding: var(--gap);
  }

  .mainline {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }

  .button {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    width: 100%;
    max-width: 200px;
    text-align: center;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    background: var(--primary);
    font-weight: bold;
  }

  .login a {
    display: block;
    font-size: 1.8rem;
    border: 1px solid var(--primary);
    text-decoration: none;
    color: var(--primary);
    padding: 1rem 2.5rem;
    background: #fff;
    border-radius: 3rem;
  }

  nav {
    position: fixed;
    top: 1.5rem;
    right: 1rem;
    left: 0;
    font-size: 1.8rem;
  }

  .login {
    width: 100%;
    margin: 0 auto;
    padding: 0.5rem 2rem;
    display: flex;
    justify-content: end;
  }

  header,
  .headline {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    max-height: 4rem;
  }

  .logo img {
    height: 4rem;
    width: 100%;
    object-fit: contain;
  }

  .headline span {
    animation-name: head;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    display: none;
    font-size: 3rem;
    text-align: center;
  }

  .headline .active {
    display: initial;
  }
`
