export default (...args) =>
  args
    .flatMap(object => {
      if (!object) return null
      if (typeof object === "string") {
        return object
      }
      return Object.entries(object).reduce(
        (str, [name, bool]) => (bool && name ? [...str, name] : str),
        []
      )
    })
    .filter(v => v)
    .join(" ") || undefined
